import Typography from '@mui/material/Typography'
import { storyblokEditable } from '@storyblok/react'
import cn from '@utils/helpers/cn'

const MuiTypography = ({ blok }) => {
  return (
    <Typography
      variant={blok.variant}
      align={blok.align || 'inherit'}
      noWrap={blok.noWrap}
      paragraph={blok.paragraph}
      className={cn(blok.class)}
      {...storyblokEditable(blok)}
    >
      {blok.text?.replace(/\\n/g, '\n')}
    </Typography>
  )
}

export default MuiTypography
